@font-face {
    font-family: 'BMFont';
    font-weight: 900;
    src: local('BMFont'), url(./font/BMDANIEL-Bold.ttf) format('truetype');
}

main {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.title {
    font-family: BMFont ;
}

.title-black{
    font-family: BMFont ;
    color: "black !important"
}
